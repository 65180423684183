import React, {useState, useEffect, useCallback } from 'react';
import {
    Routes,
    Route
  } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import OVHome from './OVHome';
import {MODE, SERVICE_URL, DEVLOCAL} from '../controls/Shared';


export default function Container() {

    const [azureUser, setAzureUser] = useState(null);
    const [user, setUser] = useState(null); 
    const [responseMsg, setResponseMsg] = useState("");

    
    const testBanner = MODE === "TEST" ? <div className="banner"><label className="warningText">WARNING! YOU ARE IN TEST MODE!</label></div> : <div />;       
    const authUrl = MODE === "TEST" ? 'https://edge-test.starisland.org/.auth/me' : 'https://edge.starisland.org/.auth/me';

    const getAzureUser = useCallback(async() => {
        await fetch(authUrl).then((response) => {
             if (response.ok) {
               return response.json();
             } else {
               return null;
             }
         })
         .then((responseJson) => {
             setAzureUser(responseJson.clientPrincipal);
         })
         .catch((error) => {
             console.log(error);
         });
        
     }, [authUrl]);

     const getUserData = useCallback(() => {
        const url = SERVICE_URL + "/getUserByGuid";

        const body = JSON.stringify({
            "AzureGuid" : azureUser.userId,
            "UserLogin" : azureUser.userDetails,
            "TestMode" : MODE === "TEST"
        });

        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
            'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                setResponseMsg("There was a problem with the request. See Event Logs.");      
                return null;
            }
        })
        .then((responseJson) => {
            setUser(responseJson[0]);
        });  
    }, [azureUser]);
 
     useEffect(() => {
        //test code to set user
        if (DEVLOCAL) {
            let testUser = {};
            testUser.userName = "Jackson Boomhower";
            testUser.userDetails = "jboomhower@starisland.org";
            setAzureUser(testUser);   
        }  else {
            getAzureUser(); 
        }                  
     }, [getAzureUser]);

     useEffect(() => {
        if (azureUser && azureUser !== null) {
            getUserData();
        }
     }, [azureUser, getUserData])
    
    return (
        user && user !== null && user.isEdgeUser ?
        <div className="mainArea">            
            <Header user={user}/>
                {testBanner}
                {responseMsg}
                <Routes>
                    <Route path="/" exact element={<OVHome  userInfo={user} />} />
                    <Route
                        path="/:navTitle"
                        element={<OVHome userInfo={user} />} />
                    <Route path="*" element={<div>Page Not Found</div>} />
                </Routes>            
            <Footer />
        </div>
        : <div className="mainArea">            
                <Header user={user}/>
                    {testBanner}
                    {responseMsg}                              
                <Footer />
            </div>

    );

}