import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BillStyles, MODE, SERVICE_URL, Table, DataYear } from '../../controls/Shared';
import { ChargePanel } from '../../controls/ChargePanel';
import { PaymentPanel } from '../../controls/PaymentPanel';
import { Bill } from '../../controls/Bill';
import Popup from '../../controls/Popup';

export default function Conference(props) {

    const [years, setYears ] = useState([]);
    const currentYear = DataYear;
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [eventName, setEventName] = useState("");
    const [eventRegistrations, setEventRegistrations] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [billSummary, setBillSummary] = useState(null);
    const [rightPanel, setRightPanel] = useState(<div />);
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState(<div />);
    const [showPopupCI, setShowPopupCI] = useState(false);
    const [popupContentCI, setPopupContentCI] = useState(<div />);        
    const [guestResponseMsg, setGuestResponseMsg] = useState("");
    const [creditCardFee, setCreditCardFee] = useState(null);

    const { paymentMethods, allBillableItems, billableItemCategories, userInfo } = props;

    const toggleComplete = (event) => {
        setRightPanel(<div />);
        setBillSummary(null);
        setSelectedEvent(event);
    };

    function ClosePopup() {
        setShowPopup(false);
    }

    function ClosePopupCI() {
        setShowPopupCI(false);
    }

    function ClearForm() {
        setEventRegistrations(null);
        setRightPanel(<div />);
        setBillSummary(null);
        setSelectedEvent(null);
    }

    function GetEventRegistrations() {
        if (eventName !== "") {
            ClearForm();
            setGuestResponseMsg("Getting Guests...");
            const body = JSON.stringify({
                "EventYear" : selectedYear,
                "Name": eventName,
                "RequestType" : "Conference",
                "TestMode" : MODE === "TEST",
                "Requestor" : userInfo.userName
              });
    
              const url = SERVICE_URL + "/getEventRegistrations";
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 406) {
                        setGuestResponseMsg("No Guests found.");
                    } else {
                        alert("Something went wrong. See Event Logs for details");
                    }              
                    return null;
                }
            })
            .then((responseJson) => {
                setGuestResponseMsg("");
                setEventRegistrations(responseJson);
            });
        }        
    }

    const GetBillSummary = useCallback((selectedEvent) => {
        const body = JSON.stringify({
            "EventRegistrationId" : selectedEvent.eventRegistrationId,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
          });

          const url = SERVICE_URL + "/getBillSummary";
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return null;
            }
        })
        .then((responseJson) => {
            //if (responseJson !== null) setResponseMsg("");
            setBillSummary(responseJson);
        });
    },[userInfo.userName]);
    
    useEffect(() => {
        if (years.length === 0) {        
          const filledYears = [];
          for (var i = currentYear; i >= currentYear - 10; i--) {
            filledYears.push(i);
          }
          setYears(filledYears);
        }      
      }, [years.length, currentYear, setYears]);
      
    const ShowBill = useCallback(() => {
        if (selectedEvent && selectedEvent !== null) {
            GetBillSummary(selectedEvent);
        } else {
            alert("Please select an Event to see a Bill!");
        }        
    }, [selectedEvent, GetBillSummary])

    const { current: billSummaryColumns } = useRef(
        [
            {
                Header: "Date",
                accessor: "itemDate",
                Cell: row => {
                    return(
                      <div>{new Date(row.value).toLocaleDateString('en-US')}</div>
                    )
                  }
            },
            {
                Header: "Description",
                accessor: "description"
            },
            {
                Header: "Charges",
                accessor:"charge",
                Cell: row => {
                    if (row.value === 0.00) {
                        return <div />;
                    } else {
                        return(
                        <div>{row.value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</div>
                        )
                    }
                  }
            },
            {
                Header: "Payments",
                accessor: "credit",
                Cell: row => {
                    if (row.value === 0.00) {
                        return <div />;
                    } else {
                        return(
                        <div>{row.value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</div>
                        )
                    }
                  }
            }
        ]
    );

    const ShowPrintBill = useCallback((chg, pmt) => {
        setPopupContent(<Bill eventRegistration={selectedEvent} billSummaryColumns={billSummaryColumns} billSummary={billSummary} totalCharge={chg} totalPayment={pmt} userInfo={userInfo}/>);
        setShowPopup(true);
    }, [selectedEvent, billSummaryColumns, billSummary, userInfo]);

    

    const CheckInGuest = useCallback((id, confirmedParking) => {       
  
        const body = JSON.stringify({
            "EventRegistrationId" : id,
            "CheckedIn" : true,
            "VaccineCard" : false,
            "NegativePcrTest" : false,
            "Notes" : "Checked In from Transactions",
            "ParkingOnSite" : confirmedParking,
            "ParkingPassNumber" : "",
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
        });
        const url = SERVICE_URL + "/updateCheckIn";
        
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json'
            }
            }).then(response => {
                if (response.ok) {
                  setShowPopupCI(false);
                  ShowBill();                               
                } else {
                  alert("An error has occurred.  Please see Event Logs for more detail.")
                }
            });
    }, [userInfo, ShowBill]);

    const OpenCheckInPopup = useCallback((id) => {
        const isGuestParkingOnSite = eventRegistrations.filter((er) => { return er.eventRegistrationId === id;})[0].parkingOnSite;
        const parkingMsg = isGuestParkingOnSite ? "is Parking On Site" : "is Not Parking On Site";
        
        const confirmedParking = "This guest " + parkingMsg + ". Is this OK?";

        const checkInPopContent = <div><br /><h4>{confirmedParking}</h4><div><button className='button' onClick={() => CheckInGuest(id, isGuestParkingOnSite)}>Yes</button><button className='button' onClick={() => CheckInGuest(id, !isGuestParkingOnSite)}>No</button></div></div>

        setPopupContentCI(checkInPopContent);
        setShowPopupCI(true);
    }, [CheckInGuest, eventRegistrations])

    useEffect(() => {
        if (billSummary !== null) {
            let chg = 0.00;
            let pmt = 0.00;
            let eventNames = [];
            billSummary.forEach((billItem) => {
                chg += billItem.charge;
                pmt += billItem.credit;
                if (!eventNames.includes(billItem.eventName)) {
                    eventNames.push(billItem.eventName);
                }
            });            
            let bal = chg - pmt;
            const allNames = [...new Set(billSummary.map(q => q.fullName + "|" + q.checkedIn + "|" + q.eventRegistrationId + "|" + q.eventName))];
            const allBills = [];
            eventNames.forEach((eventName) =>  {
                const namesByEvent = allNames.filter((x) => { 
                    const nameParts = x.split("|");
                    return nameParts[3] === eventName;
                })
                const subBillDetails = namesByEvent.map(function (nameish) {
                    const nameParts = nameish.split("|");
                    const name = nameParts[0];
                    const nameRows = billSummary.filter(function (bsum) {
                        return bsum.fullName === name && bsum.eventName === eventName;
                    })
                    const checkButton = nameParts[1] !== "true" ? <button className='greenButton'  onClick={() => OpenCheckInPopup(parseInt(nameParts[2]))}>Check In Guest</button> : <div />;        
                    const billName = name + " - " + nameRows[0].eventName;
                    const bsTable = <Table columns={billSummaryColumns} data={nameRows} hiddenFields={[]} />;
                    return <div><label>{billName}</label>{checkButton}<div style={{background: "oldlace", border: "solid"}}>{bsTable}</div><br /></div>

                });
                allBills.push(subBillDetails);
            });
            const billDetails = allBills.map((billDeet) => { return billDeet;});
            
            const guestListWithCheckIn = [];            
            allNames.forEach((item) => {
                const nameParts = item.split("|");
                const ciValue = nameParts[1] === "true" ? nameParts[0] : nameParts[0] + " \u274C";
                guestListWithCheckIn.push(ciValue);
            });
            const ccFee = (bal * .03).toFixed(2);
            setCreditCardFee(ccFee);
            const balWithFee = bal + ccFee;
            const creditCardFeeSection = bal > 0.00 && (selectedEvent.chargeCreditCardFee === null || selectedEvent.chargeCreditCardFee === undefined) 
                    ? <div>
                        <p><b><span class="confereeBillPaymentTotal">Credit Card Fees (optional): {ccFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b></p> 
                        <p><b><span class="confereeBillGrandTotal">Balance including Credit Card Fees: {balWithFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b></p>
                      </div>
                    : <div />;
            setRightPanel(<div style={{background: "white"}}>
                            <div><button className='button' onClick={() => ShowPrintBill(chg, pmt)}>Print Bill</button></div>
                            <div style={{fontWeight: "bold"}}>{eventNames.join(";")}</div>
                            <div>{guestListWithCheckIn.join(";")}</div>
                            <br />
                            <div><BillStyles>{billDetails}</BillStyles></div>
                            <br />
                            <div style={{background: "white"}}>
                            <p><b><span class="confereeBillChargeTotal">Total Charges: {chg.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b></p>
                            <p><b><span class="confereeBillPaymentTotal">Total Payments: {pmt.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b></p>
                            <p><b><span class="confereeBillGrandTotal">Balance: {bal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span></b></p>
                            {creditCardFeeSection}
                            </div>
                        </div>);
        } else if (selectedEvent !== null) {
            ShowBill();
        }      
    }, [billSummary, selectedEvent, billSummaryColumns, ShowPrintBill, ShowBill, OpenCheckInPopup])

    function ShowAddCharges() {
        if (selectedEvent && selectedEvent !== null) {
            let chg = 0.00;
            let pmt = 0.00;
            if (billSummary && billSummary !== null) {
                billSummary.forEach((billItem) => {
                    chg += billItem.charge;
                    pmt += billItem.credit;
                });  
            }                      
            let bal = chg - pmt;
            if (bal > 0.00) {
                setRightPanel(<ChargePanel pointOfSale={false} eventRegistrationId={selectedEvent.eventRegistrationId} 
                    paymentMethods={paymentMethods} allBillableItems={allBillableItems} billableItemCategories={billableItemCategories}
                    showBill={ShowBill} userInfo={userInfo}/>);
            } else {
                if (window.confirm("This bill does not have an outstanding balance.  Are you certain you want to add a charge?")) {
                    setRightPanel(<ChargePanel pointOfSale={false} eventRegistrationId={selectedEvent.eventRegistrationId} reloadEventRegs={GetEventRegistrations}
                        paymentMethods={paymentMethods} allBillableItems={allBillableItems} billableItemCategories={billableItemCategories}
                        showBill={ShowBill} userInfo={userInfo}/>);
                } else {
                    setRightPanel(<div />);
                }
            }            
        } else {
            alert("Please select an Event to Add a Charge!");
        }        
    }

    function ShowAddPayment() {
        if (selectedEvent && selectedEvent !== null) {
                let chg = 0.00;
                let pmt = 0.00;
                billSummary.forEach((billItem) => {
                    chg += billItem.charge;
                    pmt += billItem.credit;
                });            
                let bal = chg - pmt;
            if (bal > 0.00) {
                
                setRightPanel(<PaymentPanel eventRegistrationId={selectedEvent.eventRegistrationId}
                                            outstandingBalance={bal}
                                            paymentMethods={paymentMethods}
                                            showBill={ShowBill}
                                            userInfo={userInfo} />);
            } else {
                alert("You cannot add a payment to a bill without an outstanding balance. Please add additional Charges before additional Payments");
                //keeping this in in case Mike wants to go back to donations through payments
                /**if (window.confirm("This bill does not have an outstanding balance.  Are you certain you want to add a payment?")) {
                    setRightPanel(<PaymentPanel eventRegistrationId={selectedEvent.eventRegistrationId}
                        outstandingBalance={selectedEvent.outstandingBalanceAmount} 
                        paymentMethods={paymentMethods}
                        showBill={ShowBill} />);
                } else {
                    setRightPanel(<div />);
                }**/
            }  
        } else {
            alert("Please select an Event to Add a Payment!")
        }
    }

    function DeclineCCCharge() {
        const body = JSON.stringify({
            "EventRegistrationId" : selectedEvent.eventRegistrationId,
            "UserName" : "programming",     
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
        })
        const url = SERVICE_URL + "/declineFee";
          
        fetch(url, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                selectedEvent.chargeCreditCardFee = false;
                ShowBill();                               
            } else {
                alert("An error has occurred.  Please see Event Logs for more detail.")
            }
        });
    }

    function SaveCCCharge() {
        const body = JSON.stringify({
          "EventRegistrationId" : selectedEvent.eventRegistrationId,
          "BillableItemId" : 45,
          "Quantity" : 1, 
          "Amount": parseFloat(creditCardFee),
          "Notes" : 'thank you!',
          "UserName" : "programming",     
          "TestMode" : MODE === "TEST",
          "Requestor" : userInfo.userName
        });
          const url = SERVICE_URL + "/saveCharge";
          
              fetch(url, {
                  method: 'POST',
                  body: body,
                  headers: {
                      'Content-Type': 'application/json'
                  }
              }).then(response => {
                if (response.ok) {
                  //const msg = pointOfSale ? "Point of Sale Charge and Payment Successfully Created!" : "Charge Successfully Created!";
                  //alert(msg); 
                  selectedEvent.chargeCreditCardFee = true;
                  ShowBill();                               
                } else {
                  alert("An error has occurred.  Please see Event Logs for more detail.")
                }
              });
      }
    
    

    const yearSelect = years === undefined ? <div /> : 
    <select onChange={(event) => setSelectedYear(parseInt(event.target.value))}> {
        years.map((year) => {
        return <option key={year} value={year}>{year}</option>
        })
        }
    </select>;
    const popup = showPopup ? <Popup closePopup={ClosePopup} enablePrint={true}>{popupContent}</Popup> : undefined;

    const handleKeypress = e => {
        //it triggers by pressing the enter key
      if (e.charCode === 13) {
        GetEventRegistrations();
      }
    }; 


    
    const addCCFeeButton = selectedEvent !== null && (selectedEvent.outstandingBalance && (selectedEvent.chargeCreditCardFee === null || selectedEvent.chargeCreditCardFee === undefined)) 
        ? <div><button className='button' onClick={SaveCCCharge}>Add Credit Card Fee</button><button className='button' onClick={DeclineCCCharge}>Decline Credit Card Fee</button></div> : <div/>;

    const declineMessage = selectedEvent && selectedEvent !== null && selectedEvent.chargeCreditCardFee === false
        ? <div><b>This Guest has declined the charge for Credit Card Fees</b></div> : <div/>;
       
    
    const buttonArea = selectedEvent !== null
        ? <div>
            <button className='button' onClick={ShowBill}>Bill</button>
            <button className='button' onClick={ShowAddCharges}>Add Charges</button>
            <button className='button' onClick={ShowAddPayment}>Add Payments</button>
            {addCCFeeButton}
            {declineMessage}
        </div> : <div />;

    const popupCheckIn = showPopupCI ? <Popup closePopup={ClosePopupCI} enablePrint={false} makeSmall={true}>{popupContentCI}</Popup> : undefined;

    return (
        <div>
            <div className="sideBySide">
                <div style={{width: '40%'}}> 
                    <div>
                        {yearSelect}
                        <input type="text" value={eventName} onChange={(event) => setEventName(event.target.value)} onKeyPress={handleKeypress}/>
                        <button className='button' onClick={GetEventRegistrations}>Search</button>
                    </div>                    
                    <div className="boxedContent">                
                        <div className='item-list'>
                            {eventRegistrations !== null ? (eventRegistrations.map((item) => (
                                <div className='item-container'>
                                    <div className='item-name' onClick={() => toggleComplete(item)}>
                                    {item === selectedEvent ? (
                                        <span className={!item.outstandingBalance ? 'highlighted-balanced' : 'highlighted-unbalanced'}>{item.fullName} - {item.abbreviation}</span>
                                        ) : (       
                                        <span className={!item.outstandingBalance ? 'balanced' : 'unbalanced'}>{item.fullName} - {item.abbreviation}</span>
                                        )}
                                    </div>
                                </div>
                            ))) : <div>{guestResponseMsg}</div>}
                        </div>
                    </div>
                    {buttonArea}
                </div>
                <div style={{width: '60%'}}>
                    {rightPanel}
                </div>
            </div>
            {popup}
            {popupCheckIn}
        </div>
    )
}