import React, { useState, useRef, useEffect, useCallback } from 'react';
import {MODE, Styles, SERVICE_URL} from '../controls/Shared';

import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';

export default function CheckIn(props) {

    const [checkInData, setCheckInData] = useState(null);
    const [checkInDate, setCheckInDate] = useState(new Date());
    const [responseMsg, setResponseMsg] = useState("");
    const [sortBy, setSortBy] = useState("name");
    const [ciGridScrollPosition, setCiGridScrollPosition] = useState(0);

    const { userInfo } = props;
    let checkInTable = <div />;
    let userMsg = <div />;

    function onScroll() {
        const scrollDiv = document.querySelector("#checkInDiv");
        if (scrollDiv !== null) {
            console.log(scrollDiv.offsetTop - window.scrollY);

        }
        
    }

    const GetCheckInData = useCallback((sortBy) => {
        if (checkInDate === undefined || checkInDate === null) {
            alert("Please select a date first");
        } else {
            setCheckInData(null);
            const url = SERVICE_URL + "/getCheckIns";
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({ "CheckInDate" : checkInDate.toLocaleDateString("en-CA"), "SortByName" : sortBy === "name", "TestMode" : MODE === "TEST", "Requestor" : userInfo.userName }),
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then((response) => {
                    if (response.ok) {
                      return response.json();
                    } else if (response.status === 406) {
                        setResponseMsg("No Data was found for the Request Date.");
                    } else {
                        setResponseMsg("A problem occurred with the data collection. Please see Event Logs.");
                    }
                })
                .then((responseJson) => {
                    if (responseJson != null) {
                        setResponseMsg("");
                        setCheckInData(responseJson);
                        document.getElementById("ciArea").scrollTop = ciGridScrollPosition;   
                    }                    
                });             
        }        
    },[checkInDate, ciGridScrollPosition, userInfo.userName]);


    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });

    const [checkedIn, setCheckedIn] = useState(false);
    const [parkingOnSite, setParkingOnSite] = useState(false);
    const [parkingPassNumber, setParkingPassNumber] = useState("");

    /**
     *
     * @param id
     */
    const onEdit = ({id, checkedIn, parkingOnSite, parkingPassNumber}) => {
        setCiGridScrollPosition(document.getElementById("ciArea").scrollTop);
        setInEditMode({
            status: true,
            rowKey: id
        })
        setCheckedIn(checkedIn);
        setParkingOnSite(parkingOnSite);
        setParkingPassNumber(parkingPassNumber);
    }

    /**
     *
     */
    const updateInventory = (body) => {
        const url = SERVICE_URL + "/updateCheckIn";
        
            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
                }).then(json => {
                // reset inEditMode and unit price state values
                onCancel();
                // fetch the updated data
                GetCheckInData(sortBy);
            })
    }

    /**
     *
     * @param id -The id of the product
     * @param newUnitPrice - The new unit price of the product
     */
    const onSave = ({id, checkedIn, parkingOnSite, parkingPassNumber}) => {
        const body = JSON.stringify({
            "EventRegistrationId" : id,
            "CheckedIn" : checkedIn,
            "ParkingOnSite" : parkingOnSite,
            "ParkingPassNumber" : parkingPassNumber === undefined ? "" : parkingPassNumber,
            "TestMode" : MODE === "TEST",
            "Requestor" : userInfo.userName
        });
        updateInventory(body);
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
    }

    const parkingInputRef = useRef(null);

    useEffect(() => {
        GetCheckInData(sortBy);
    },[checkInDate, GetCheckInData, sortBy])


    useEffect(() => {
        if (inEditMode.status) {
            parkingInputRef.current.focus();
        }        
    }, [parkingPassNumber, inEditMode.status]);


    if (checkInData !== null) {
        checkInTable = <table id="ciTable">
        <thead style={{position: "sticky"}}>
        <tr>
            <th></th>
            {sortBy !== "name" ? <th>Party</th> : <div/>}
            <th>Guest Name</th>
            <th>Event</th>
            <th>Arrival</th>
            <th>Departure</th>
            <th>Building</th>
            <th>Room</th>
            <th>Checked In</th>
            <th>Parking On Site</th>
            <th>Parking Pass Number</th>            
        </tr>
        </thead>
        <tbody>
        {
            checkInData.map((item) => (
                <tr key={item.eventRegistrationId}>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <React.Fragment>
                                    <button
                                        className={"button-checkin"}
                                        onClick={() => onSave({id: item.eventRegistrationId, checkedIn: checkedIn,                                             
                                        parkingOnSite: parkingOnSite, parkingPassNumber: parkingPassNumber})}
                                    >
                                        Save
                                    </button>

                                    <button
                                        className={"button-checkin"}
                                        style={{marginLeft: 8}}
                                        onClick={() => onCancel()}
                                    >
                                        Cancel
                                    </button>
                                </React.Fragment>
                            ) : (
                                <button
                                    className={"button-checkin"}
                                    onClick={() => onEdit({id: item.eventRegistrationId, checkedIn: item.checkedIn, parkingOnSite: item.parkingOnSite, parkingPassNumber: item.parkingPassNumber})}
                                >
                                    Edit
                                </button>
                            )
                        }
                    </td>
                    {sortBy !== "name" ? <td>{item.partyName}</td> : <div/>}
                    <td>{item.guest}</td>
                    <td>{item.eventName}</td>
                    <td>{new Date(item.arrivalDate).toLocaleDateString('en-US')}</td>
                    <td>{new Date(item.departureDate).toLocaleDateString('en-US')}</td>
                    <td>{item.buildingName}</td>
                    <td>{item.roomNumber}</td>
                    <td>
                        {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input type="checkbox" className='checkbox-checkin'
                                    checked={checkedIn}
                                    onChange={(event) => setCheckedIn(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.checkedIn} readOnly="true" className='checkbox-checkin'/>
                            )
                        }
                    </td>
                    <td> {
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input type="checkbox" className='checkbox-checkin'
                                    checked={parkingOnSite}
                                    onChange={(event) => setParkingOnSite(event.target.checked)}
                                />
                            ) : (
                                <input type="checkbox" checked={item.parkingOnSite} readOnly="true" className='checkbox-checkin'/>
                            )
                        }</td>
                    <td>{
                            inEditMode.status && inEditMode.rowKey === item.eventRegistrationId ? (
                                <input value={parkingPassNumber} ref={parkingInputRef} style={{height: '10vh'}}
                                       onChange={(event) => setParkingPassNumber(event.target.value)}
                                />
                            ) : (
                                item.parkingPassNumber
                            )
                        }</td>
                    
                </tr>
            ))
        }
        </tbody>
    </table>
    } else {
        userMsg = <div className="warning">{responseMsg}</div>;
    }

    const SortAndRefresh = useCallback((sortValue) => {
        setSortBy(sortValue);
        GetCheckInData(sortValue);       
    },[GetCheckInData]);  

    return (
        
        <div className="sideBySide">
            <div>
                <Calendar onChange={setCheckInDate} value={checkInDate}/>
                
                <div>
                    <button className="button-checkin" type="button" disabled={checkInData === null || sortBy === "name"}  onClick={() => SortAndRefresh("name")}>Sort by Name</button>
                    <button className="button-checkin" type="button" disabled={checkInData === null || sortBy === "party"}  onClick={() => SortAndRefresh("party")}>Sort by Party</button>
                </div>
            </div>
            <div id="ciArea" className="ciContentArea" style={{width: '80%',height: '150vh'}}>
                {userMsg}
                <Styles id="checkInDiv" onScrollCapture={() => onScroll()}>
                    {checkInTable}
                </Styles>
            </div>
        </div>
    )
}