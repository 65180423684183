import React from "react";

export default function Popup({ closePopup, enablePrint, children, makeSmall = false}) {

    function printElement() {
        const elem = document.getElementById("popupContent")
        var domClone = elem.cloneNode(true);
    
        var $printSection = document.getElementById("printSection");
    
        if (!$printSection) {
            $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }
    
        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        window.print();
    }

    const printButton = enablePrint ? <button className='button' onClick={printElement}>Print</button> : <div />;
    const style1 = makeSmall ? "popup-small" : "popup";
    const style2 = makeSmall ? "popup-small__popupContent" : "popup__popupContent";

    return (
        <div className={style1}>
            <div className={style2}>
                {printButton}
                <button className='button' onClick={closePopup}>Close
                </button>
                <div id="popupContent">
                    {children}
                </div>                
            </div>
        </div>
    )
}