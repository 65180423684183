
import React from 'react';
import BatchOpenDrawer from './TransactionPages/BatchOpenDrawer';
import Conference from './TransactionPages/Conference';
import PointOfSale from './TransactionPages/PointOfSale';
import Reports from './TransactionPages/Reports';

export default function Transactions(props) {

    const { paymentMethods, allBillableItems, billableItemCategories, userInfo } = props;

    const data = [
        {id : '1',
        tabTitle: "Conference",
        tabContent: <Conference paymentMethods={paymentMethods} allBillableItems={allBillableItems} billableItemCategories={billableItemCategories} userInfo={userInfo}/>
        },
        {id : '2',
        tabTitle: "Point Of Sale",
        tabContent: <PointOfSale paymentMethods={paymentMethods} allBillableItems={allBillableItems} billableItemCategories={billableItemCategories} userInfo={userInfo}/>
        },
        {id : '3',
        tabTitle: "Batch Open Drawer",
        tabContent: <BatchOpenDrawer userInfo={userInfo}/>
        },
        {id : '4',
        tabTitle: "Reports",
        tabContent: <Reports userInfo={userInfo}/>
        }
    ];

    function Tab(props){
        
        const [visibleTab, setVisibleTab] = React.useState(props.data[0].id)
      
        const listTitles = props.data.map((item) => 
            <li onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
        )       
                                         
        const listContent = props.data.map((item) => 
            <p style={visibleTab === item.id ? {} : {display: 'none'}}>{item.tabContent}</p>
        )
        
        return(
            <div className="tabs">
              <ul className="tabs-titles">
                {listTitles}
              </ul>
              <div className="tab-content">
                 {listContent}
              </div>
            </div>
          )
      }
    
    return (
        <div>
            <Tab data={data}/>
        </div>
    );
}